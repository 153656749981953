// Custom Helper Functions
/**
 * Dispatches a custom event with the given name.
 * @param {string} name - The name of the custom event.
 */
function tabCustomEvent(name, detail={}) {
    // Create a new custom event with the provided detail object.
    const customEvent = new CustomEvent(name, { detail: detail });
    // Dispatch the custom event to the window.
    window.dispatchEvent(customEvent);
}

/**
 * Applies a global filter by dispatching a custom event.
 *
 * @param {Object} data - The data to be passed as the detail of the custom event.
 */
function applyGlobalFilter(data) {
    // Dispatch a custom event named 'onGlobalFilter' with the provided data.
    // The data will be available in the event's detail property.
    tabCustomEvent('onGlobalFilter', data);
}


// Fomrio Helper Functions
/**
 * Refreshes the parameters of the given instance with the provided parameters.
 * If the instance has a value and the parameters are not null, it updates the
 * 'parameters' property of the instance's value with the provided parameters.
 *
 * @param {any} instance - The instance to refresh the parameters for.
 * @param {object} params - The parameters to set on the instance's value.
 * @returns {object} - The updated value of the instance.
 */
function refreshParameters(instance, params) {
    // If the provided parameters are not null, update the instance's value with the provided parameters
    if(params){
        // Get the instance's value
        const value = instance.getValue();
        const parameters = value?.parameters || {};
        const component = value?.component && Object.keys(value.component).length !== 0 ? value.component : instance?.component;

        Object.keys(params).forEach(key => {
            if(params[key] !== undefined) parameters[key] = params[key];
        });

        return {
            ...value,
            parameters: parameters,
            component: component
        };
    }
}

/**
 * Refreshes the configurations of the given instance with the provided configurations.
 * If the instance has a value and the configurations are not null, it updates the
 * 'component' property of the instance's value with the provided configurations.
 *
 * @param {any} instance - The instance to refresh the configurations for.
 * @param {object} config - The configurations to set on the instance's value.
 * @returns {object} - The updated value of the instance.
 */
function refreshConfigurations(instance, config) {
    // If the provided configurations are not null, update the instance's value with the provided configurations
    if(config){
        const value = instance.getValue();
        const component = value?.component && Object.keys(value.component).length !== 0 ? value.component : instance?.component;

        Object.keys(config).forEach(key => {
            if(config[key] !== undefined) component['Configuration']['componentValue'][key] = config[key];
        });

        return {
            ...value,
            component: component
        };
    }
}


// Date functions
/**
 * Formats a given UTC string into a readable date and time string in the format 'DD-MM-YYYY HH:MM:SS AM/PM'.
 *
 * @param {string} utcString - The UTC string to format.
 * @return {string} The formatted date and time string.
 */
function formatUTCDate(utcString) {
  // Create a new Date object from the given UTC string
  const date = new Date(utcString);

  // Extract the day, month, and year components from the date object
  const day = String(date.getDate()).padStart(2, '0'); // Pad the day with leading zeros
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad the month with leading zeros and add 1 since months are zero-indexed
  const year = date.getFullYear(); // Get the full year

  // Extract the hours, minutes, and seconds components from the date object
  let hours = date.getHours(); // Get the hours in local time
  const minutes = String(date.getMinutes()).padStart(2, '0'); // Pad the minutes with leading zeros
  const seconds = String(date.getSeconds()).padStart(2, '0'); // Pad the seconds with leading zeros

  // Determine whether it is AM or PM based on the hours
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert the hours to 12-hour format and handle midnight (0 hours)

  // Format the time as HH:MM:SS AM/PM
  const formattedTime = `${String(hours).padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;

  // Format the date as DD-MM-YYYY and combine with the time
  const formattedDate = `${day}-${month}-${year} ${formattedTime}`;

  return formattedDate;
}

/**
 * Returns a string representing the time elapsed since the given date.
 * The date string should be in the format 'DD-MM-YYYY HH:mm:ss AM/PM'.
 * @param {string} dateStr - The date string to convert to a time ago string.
 * @returns {string} - A string representing the time elapsed since the given date.
 */
function timeAgo(dateStr) {
    // Parse the date string
    if(!dateStr)  return dateStr; // Return the original string if it is falsy
    const parts = dateStr.match(/(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2}) (AM|PM)/);
    if (!parts) {
        return 'Date is incorrect'; // Return an error string if the date is not in the expected format
    }

    // Extract the individual parts of the date string
    let [ , day, month, year, hour, minute, second, period] = parts;
    day = parseInt(day, 10);
    month = parseInt(month, 10) - 1; // months are zero-indexed in JavaScript
    year = parseInt(year, 10);
    hour = parseInt(hour, 10);
    minute = parseInt(minute, 10);
    second = parseInt(second, 10);

    // Convert to 24-hour format if PM
    if (period === 'PM' && hour < 12) {
        hour += 12;
    } else if (period === 'AM' && hour === 12) {
        hour = 0;
    }

    // Create a new Date object from the parsed parts
    const pastDate = new Date(year, month, day, hour, minute, second);
    const now = new Date();

    // Check if the date is valid
    if (isNaN(pastDate.getTime())) {
        return 'Date is incorrect'; // Return an error string if the date is not valid
    }

    // Calculate the time elapsed in seconds
    const secondsPast = Math.floor((now.getTime() - pastDate.getTime()) / 1000);

    // Return the appropriate time ago string based on the elapsed time
    if (secondsPast < 60) {
        return `${secondsPast} seconds ago`;
    }
    const minutesPast = Math.floor(secondsPast / 60);
    if (minutesPast < 60) {
        return `${minutesPast} minutes ago`;
    }
    const hoursPast = Math.floor(minutesPast / 60);
    if (hoursPast < 24) {
        return `${hoursPast} hours ago`;
    }
    const daysPast = Math.floor(hoursPast / 24);
    if (daysPast <= 30) {
        return daysPast === 1 ? '1 day ago' : `${daysPast} days ago`;
    }
    if (daysPast <= 365) {
        const monthsPast = Math.floor(daysPast / 30);
        return monthsPast === 1 ? '1 month ago' : `${monthsPast} months ago`;
    }
    const yearsPast = Math.floor(daysPast / 365);
    return yearsPast === 1 ? '1 year ago' : `${yearsPast} years ago`;
}


// Permission Helper Functions
/**
 * Checks if the user has any of the specified roles.
 * @param {string[]} roles - The IDs or names of the roles to check for.
 * @returns {boolean} A boolean indicating whether the user has any of the specified roles.
 */
function doesUserIs(...roles) {
    // Get the roles from the user info in local storage
    const loggedInUserRoles = JSON.parse(localStorage.getItem('userInfo') ?? '{}')?.user?.Roles ?? [];
    
    // Check if any of the roles have the specified ID or name and return the result
    return roles?.some(role => loggedInUserRoles?.some(loggedInUserRole => 
        loggedInUserRole?.ID?.toLowerCase() === role?.toLowerCase() || loggedInUserRole?.RoleName === role
    ));
}



// /**
//  * Opens a screen with the given screen ID and optionally sets the app ID in local storage.
//  *
//  * @param {string} screenId - The ID of the screen to open.
//  * @param {string|null} appId - The ID of the app to set in local storage. If null, app ID is not set.
//  */
// function openScreenWithId(screenId, appId = null) {
//     // Set the app ID in local storage if it is not null
//     if (appId !== null) {
//         localStorage.setItem('appId', appId);
//     }

//     // Redirect to the screen with the given screen ID
//     window.location.pathname = `screen/${screenId}`;
// }


function overdueTask(dateStr) {
    if(!dateStr)  return  false;
    const parts = dateStr.match(/(\d{4})-(\d{2})-(\d{2})/);
    if (!parts) return false;
  
    const [, year, month, day] = parts;
    const pastDate = new Date(year, month - 1, day); // month is 0-indexed
    const now = new Date();
  
    // Set both dates to start of day for accurate comparison
    pastDate.setHours(0, 0, 0, 0);
    now.setHours(0, 0, 0, 0);
  
    if (pastDate < now) {
      return "overdue-task";
    } else {
      return "";
    }
  }
  /**
   * Checks if the user has a configured permission with the given ID.
   * @param {string} PermissionId - The ID of the permission to check for.
   * @returns {boolean} A boolean indicating whether the user has the specified permission.
   */
  function doesHaveConfiguredPermission(PermissionId) {
    const tabUserPermissions = JSON.parse(sessionStorage.getItem("tabUserPermissions"));
    if (!tabUserPermissions || tabUserPermissions?.length === 0) {
        return false;
    }
    return (tabUserPermissions)?.some((permission) => 
        permission?.PermissionId?.toLowerCase() === PermissionId?.toLowerCase()
    ) ?? false;
}
/**
 * Takes a record info icon and returns the image URL.
 * If the icon is a string, it is parsed as JSON to extract the image URL.
 * If the icon is null, undefined, or an empty string, null is returned.
 * If the icon is an object and has a value property, the value property is returned.
 * Otherwise, null is returned.
 * @param {string|object} icon - The record info icon to parse.
 * @returns {string|null} The image URL or null if the icon is invalid.
 */
function getImageUrl(icon) {
    if (icon === null || icon === undefined || icon === "") return null;
    if (typeof icon === 'object' && icon.value) {
        return icon.value;
    } else if (typeof icon === 'string') {
        const parsedIcon = JSON.parse(icon);
        if (parsedIcon && parsedIcon.value) {
            return parsedIcon.value;
        }
    }
    return null;
}
