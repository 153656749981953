// TAB UTILS EVENT CALLER
function callTabUtilsEvent(eventName, detail) {
    const customEvent = new CustomEvent("tabUtilsEvent", {
        detail: {
            eventName: eventName,
            data: detail,
        },
    });
    window.dispatchEvent(customEvent);
}

// navigate to screen
function navigateToScreen(screenId, dataId = null) {
    callTabUtilsEvent("navigateToScreen", {
        screenId: screenId,
        parameters: dataId ? {Id: dataId} : null,
    });
}

// navigate to new window
function navigateToNewWindowScreen(screenId, dataId = null) {
    callTabUtilsEvent("navigateToNewWindowScreen", {
        screenId: screenId,
        parameters: dataId ? {Id: dataId} : null,
    });
}

// copy to clipboard
function copyToClipboard(text) {
    const tempInput = document.createElement("input");
    tempInput.value = text;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    notify("success", "", "Copied to clipboard!", {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        easing: 'ease-in',
        easeTime: 300,
        extendedTimeOut: 2000,
    });
}

// copy current url
function copyCurrentUrlToClipboard() {
    const currentUrl = window.location.href;
    const tempInput = document.createElement("input");
    tempInput.value = currentUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    notify("success", "", "Copied to clipboard!", {
        timeOut: 1000,
        positionClass: 'toast-top-center',
        easing: 'ease-in',
        easeTime: 300,
        extendedTimeOut: 2000,
    });
}

// notify
function notify(type, title, message, configuration = {}) {
    callTabUtilsEvent("notify", {
        type: type,
        title: title,
        message: message,
        configuration: configuration
    });
}
